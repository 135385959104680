import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/plugins'
import router from './router'
import api from './modules/api'
import {DATE_FORMAT_OPTIONS, DATETIME_FORMAT_OPTIONS} from "@/modules/SharedFunctions";
import Keycloak from 'keycloak-js'


Vue.config.productionTip = false
api.backendUrl = window.VUE_APP_MAIN_BACKEND_URL

let initOptions = {
    url:  window.VUE_APP_KEYCLOAK_URL,
    realm:  window.VUE_APP_KEYCLOAK_REALM,
    clientId:  window.VUE_APP_KEYCLOAK_CLIENT_ID,
    onLoad: 'login-required'
}

let keycloak = new Keycloak(initOptions);
Vue.prototype.$keycloak = keycloak

Vue.filter('df', function (value) {
    return value != null ? value : '—';
})


Vue.filter('formatPrice', function (value) {
  if (value != null) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₽";
  } else return('—')
})

function formatDateTime(value, options) {
    return value ? (new Date(value)).toLocaleDateString('ru-RU', options) : '—'
}

Vue.filter('formatDate', function (value) {
    return formatDateTime(value, DATE_FORMAT_OPTIONS)
})
Vue.filter('formatDateTime', function (value) {
  return formatDateTime(value, DATETIME_FORMAT_OPTIONS)
})

keycloak.init({onLoad: initOptions.onLoad, checkLoginIframe: false}).then(async (auth) => {
    if (!auth) {
        //window.location.reload()
    } else {
        api.token = keycloak.token
        const info = await keycloak.loadUserInfo();
        console.log(keycloak, info);
        Vue.prototype.$user = {
            id: keycloak.idTokenParsed.exp,
            userName: info.name,
            roles: keycloak.realmAccess.roles
        }

        /* eslint-disable no-new */
        new Vue({
            el: '#app',
            render: h => h(App, {props: {keycloak: keycloak}}),
            vuetify,
            router
        })
    }

    // Token Refresh
    setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
            api.token = keycloak.token;
            if (!refreshed) {
                // console.log('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
            }
        }).catch(() => {
            console.log('Failed to refresh token')
        })
    }, 6000)
}).catch((e) => {
    console.log('Authentication Failed: ' + e)
})