import api from "@/modules/api";
import * as uuid from "uuid";
/* global BigInt */

export function debounce(f, ms) {
    let isCooldown = false;
    return function() {
        if (isCooldown) return;
        f.apply(this, arguments);
        isCooldown = true;
        setTimeout(() => isCooldown = false, ms);
    };
}

export function mapToObject(obj){
    let res = []
    for(let e in obj)
        res.push({title: obj[e], value: e})
    return res
}

export function normalizeNumber(v, min, max, maxDecimalLength){
    if(!v) return null;
    v = String(v).replace(',', '.').trim().replaceAll(' ', '');

    if(v.endsWith('.0')) v = v.slice(0, -2)
    if(v.endsWith('.00')) v = v.slice(0, -3)
    // if(String(Number(v)) !== v) return null;
    if(v.split('.')?.[1]?.length > maxDecimalLength) return null;
    if(Number(v) > Number(max) || Number(v) < Number(min)) return null;
    return +v;
}

export function deleteNullFields(obj, deleteEmptyStrings = false){
    for(let prop in obj){
        if(obj[prop] == null || (deleteEmptyStrings && obj[prop] === "")) delete obj[prop];
    }
    return obj;
}

export function dictToObject(dict){
    let res = {}
    for(let e of dict)
        res[e.code] = e.title
    return res
}


export function getExpertShortName(expert){
    if(expert == null) return null
    return expert.surname + ' ' + (expert.name ? expert.name.charAt(0) + '.' : '') + ' ' +
        (expert.middleName ? expert.middleName.charAt(0) + '.' : '')
}

const readRequestsCache = new Map()
export async function loadDataToObject(url, prop, cache = false){
    if(cache) {
        let cached = readRequestsCache.get(url)
        if(cached) {
            if(cached instanceof Promise) cached = (await cached).payload
            if(cached instanceof Error){
                this.apiError = true
                return false
            } else {
                this[prop] = cached
                return true
            }
        }
    }
    const promise = api.get(url)
    readRequestsCache.set(url, promise)
    const res = await promise
    if (res.ok) {
        readRequestsCache.set(url, res.payload)
        this[prop] = res.payload
        return true
    } else {
        readRequestsCache.set(url, new Error(res.error))
        this.apiError = true
        return false
    }
}

export async function loadData(url, cache = false){
    const thisObject = {}
    const res = await loadDataToObject.call(thisObject, url, 'prop', cache)
    if(res){
        return thisObject.prop
    }
    else {
        return null
    }

}

export function userInOneOfRoles(...roles){
    for(let role of roles) {
        if(this.$user?.roles?.includes(role)) {
            return true
        }
    }
    return false
}

export async function downloadFile(path, querySpec) {
    const req = await api.getFile(path, querySpec)
    console.log(req.payload)
    const urln = URL.createObjectURL(req.payload)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.download = req.fileName
    a.href = urln
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(urln)
}

export function getQueryObject(){
    const result = {}
    const search = new URLSearchParams(window.location.search)
    for(const [key, value] of search) {
        result[key] = value;
    }
    return result
}

const systemImageContainerId = window.VUE_APP_SYSTEM_IMAGE_CONTAINER_ID;

function calculateCheckFn(containerId, fileId){
    const x = uuid.parse(fileId).slice(0,8).reduce((a, b) => a << 8n | BigInt(b), 0n);
    let c = Math.abs(Math.sin(Number(BigInt.asIntN(32, x)) * containerId) * 1000);
    return Math.floor((c - Math.floor(c)) * 102922323);
}
const getPublicFilePath = () => `${api.backendUrl}/storage/public/file`;
export const getImagePath =  fileId => getFilePublicUrl(systemImageContainerId, fileId);

export const getFilePublicUrl = (containerId, fileId) =>
    `${getPublicFilePath()}/${containerId}/${fileId}/${calculateCheckFn(containerId, fileId)}`
